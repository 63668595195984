import styled from 'styled-components';
import {Col} from 'react-awesome-styled-grid';

export const TextWrapper = styled(Col)`
  > * {
    margin-bottom: 11px;
    color: ${({theme}) => theme.colors.gray1};
  }

  a Span {
    font-weight: bold;
    font-size: 18px;
  }

  a {
    text-decoration: underline;
  }
`;

export const IndexStyle = styled.div`
  background: white;
  .colors {
    ${Col} {
      > div {
        display: flex;
        justify-content: space-between;

        > span {
          width: 107px;
          height: 107px;
          justify-content: center;
        }

        > p {
          flex-direction: row;
          justify-content: center;
        }

        span:nth-child(1) {
          background-color: ${({theme}) => theme.colors.primary};
        }

        span:nth-child(2) {
          background-color: ${({theme}) => theme.colors.primary2};
        }

        span:nth-child(3) {
          background-color: ${({theme}) => theme.colors.primary3};
        }
      }
    }
  }
  .colors2 {
    ${Col} {
      > div {
        display: flex;
        justify-content: space-between;

        > span {
          width: 107px;
          height: 107px;
          justify-content: center;
        }

        > p {
          flex-direction: row;
          justify-content: center;
        }

        span:nth-child(1) {
          background-color: ${({theme}) => theme.colors.secondary};
        }

        span:nth-child(2) {
          background-color: ${({theme}) => theme.colors.secondary2};
        }

        span:nth-child(3) {
          background-color: ${({theme}) => theme.colors.secondary3};
        }
        span:nth-child(4) {
          background-color: ${({theme}) => theme.colors.errorcolor};
        }
      }
    }
  }

  .elements {
    ${Col} {
      > div {
        justify-content: space-around;
        display: flex;

        > p {
          text-align: center;
        }
      }
    }
  }
`;
