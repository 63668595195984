import * as React from 'react';
import {Heading, Text, Span, FontWeight} from 'styled-typography';
import {Row, Col, Container} from 'react-awesome-styled-grid';
import {TextWrapper, IndexStyle} from './_styles';
import Layout from '../../components/Layout';
import Card from '../../components/core/Card';
import CardCategory from '../../components/core/CardCategory';
import ButtonIcon from '../../components/core/ButtonIcon';
import Button from '../../components/core/Button';

const IndexPage = () => {
  return (
    <Layout>
      <IndexStyle as="main" direction="column">
        <Container margin="10px">
          {/* Colors */}
          <Row>
            <Col md="12">
              <br />
              <br />
              <Heading as="h2" level="2" margin="50px">
                Colors primary
              </Heading>
              <br />
              <br />
            </Col>
          </Row>
          <Row className="colors">
            <Col md={4}>
              <div>
                <span />
                <span />
                <span />
                <span />
              </div>
              <br />
              <div>
                <p>#4B4B4B</p>
                <p>#CDAD73</p>
                <p>#414141</p>
                <p> ------------- </p>
              </div>
              <br />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <br />
              <br />
              <Heading as="h2" level="2" margin="50px">
                Colors secundary
              </Heading>
              <br />
              <br />
            </Col>
          </Row>
          <Row className="colors2">
            <Col md={4}>
              <div>
                <span />
                <span />
                <span />
                <span />
              </div>
              <br />
              <div>
                <p>#9B9FA8</p>
                <p>#D7D9DC</p>
                <p>#EFF0F1</p>
                <p>#E93051 (Error)</p>
              </div>
              <br />
            </Col>
          </Row>

          {/* Typography */}
          <Row>
            <Col md="12">
              <Heading as="h2" level="2">
                Typography
              </Heading>
              <br />
            </Col>
          </Row>

          <Row>
            <TextWrapper md="12">
              <Heading as="h1" fontWeight={FontWeight.Bold} level="1">
                Heading
              </Heading>
              <Heading as="h2" level="2">
                Heading 2
              </Heading>
              <Heading as="h3" level="3">
                Heading 3
              </Heading>
              <Text>Text</Text>
              <Heading as="h3" level="4">
                Span
              </Heading>
              <a href="">Link</a>
            </TextWrapper>
          </Row>

          {/* Interface Elements */}
          <Row>
            <Col md="12">
              <br />
              <br />
              <Heading as="h4" level="2">
                Interface Elements
              </Heading>
              <br />
              <br />
            </Col>
          </Row>
          <Row className="elements">
            <TextWrapper xs={3} sm={4} md={4} lg={6}>
              <div>
                <ButtonIcon type="button">Let´s Talk</ButtonIcon>
                <ButtonIcon type="button" background-Color="red">
                  Let´s Talk
                </ButtonIcon>
                <ButtonIcon type="button">Let´s Talk</ButtonIcon>
                <Button type="button">Let´s Talk</Button>
              </div>
              <div>
                <Span>Normal</Span>
                <Span>Hover</Span>
                <Span>Focus</Span>
              </div>
            </TextWrapper>
            <br />
            <br />
          </Row>

          <Row>
            <Col md="2">
              <br />
              <br />
              <br />
              <Card width="307px" height="360px"></Card>
              <br />
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <br />
              <br />
              <br />
              <CardCategory background="#FFE8D9"></CardCategory>
              <br />
            </Col>
          </Row>
        </Container>
      </IndexStyle>
    </Layout>
  );
};

export default IndexPage;
